<template>
  <section class="relative md:pb-14">
    <article class="bg-pink-salmon-200 md:bg-white pt-14 md:pt-8 md:max-w-1200 md:mx-auto ">
      <header class="relative hidden md:block">
        <h2 class="text-3xl font-bold text-center mb-8">{{title}}</h2>
        <!--返回-->
        <p v-if="backFlag" class="text-lg -mt-3 absolute top-1/2 left-0">
          <router-link :to="{name: 'login'}" class="flex items-center">
            <a-icon type="arrow-left" class="mr-2" />回首頁
          </router-link>
        </p>
      </header>
      <div class="bg-white relative rounded-t-2xl px-6 py-4 md:p-0 h-inherit">
        <slot></slot>
      </div>
    </article>
  </section>
</template>


<script>

export default {
  props:{
      title: {
      default: '註冊',
      type: String
    },
    backFlag: {
      type: Boolean,
      default: true
    },
    
  },
  data() {
    return {}
  },
  }
</script>

<style scoped>
.icon-back:before {
  content: "\e906";
}
</style>