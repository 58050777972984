<template>
  <div class="auth-bg">
    <!--page-header 頁面版頭-->
    <header class="app-pink-header block md:hidden">
      <div class="header-con">
        <div v-if="backFlag">
          <h1>{{ title }}</h1>
          <button class="btn-page btn-back" @click="$emit('onBack')"><i>back</i></button>
        </div>
        <div v-if="!backFlag">
          <h1 class="pl-4">{{ title }}</h1>
        </div>
      </div>
    </header>
    <header class="app-header hidden md:block relative">
      <div class="header-con">
        <h1 class="branding ">
          <router-link :to="{ name: 'home' }">
            揪美美 ‧ Chill May May
          </router-link>
        </h1>
      </div>
    </header>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '註冊'
    },
    backFlag: {
      type: Boolean,
      default: true
    }
  }
}
</script>
