<template>
  <AuthContainer @onBack="handleBack" :backFlag="false" title="交易結果">
    <AuthBackContainer :backFlag="false" title="交易結果">
      <article class="h-inherit md:h-auto md:max-w-1200 md:mx-auto">
        <div class=" md:bg-white pt-2 min-h-screen h-min-full ">
          <!--結帳-->

          <!--交易成功-->
          <section class="bg-white mt-1 mb-4" v-if="isPaymentSuccess">
            <div class="container-wrapper">
              <article class="flex flex-col  items-center">
                <img class="w-1/2 md:w-60" src="../../assets/img/cart/order_done.png" />
                <h6 class="text-brown text-xl md:text-2xl md:mb-2">交易成功</h6>
              </article>
            </div>
          </section>
          <!--交易失敗-->
          <section class="bg-white mt-1 mb-4" v-else>
            <div class="container-wrapper">
              <article class="flex flex-col  items-center">
                <img class="w-1/2 md:w-60" src="../../assets/img/cart/order_error.png" />
                <h6 class="text-brown text-xl md:text-2xl md:mb-2">交易失敗</h6>
              </article>
            </div>
          </section>

          <!--付款總金額-->
          <section class="bg-white mt-3 md:w-1/2 md:mx-auto">
            <div v-if="orderItem.amount" class="container-wrapper pb-0  justify-center  md:pb-4">
              <div class="flex mb-2 items-center pb-4 border-b">
                <img class="cart-item-img mr-2 " :src="productImage"/>
                <div>
                  <p class="text-brown font-medium md:text-lg">{{productName}}</p>
                </div>
              </div>
              <div class="flex flex-row justify-center py-2">
                <span class="text-brown text-xl mr-2 md:text-lg">付款總金額</span>
                <span class="text-pink-salmon font-semibold text-xl ml-2 md:text-lg">{{amount}}</span>
              </div>
            </div>

            <div class="flex items-center justify-center m-4 py-2 border-b">
              <ul v-if="isATMpayment">
                <li>訂單號碼: {{ $route.query.CustomField1 }}</li>
                <li>銀行代碼: {{ $route.query.BankCode }}</li>
                <li>銀行帳號: {{ $route.query.vAccount }}</li>
                <li>截止時間: {{ expireDate }}</li>
              </ul>
              <ul v-else>
                <li>訂單號碼: {{ $route.query.CustomField1 }}</li>
              </ul>
            </div>
            <div class="m-4 py-2">
              <p class="mb-0 text-center">感謝各位團主的訂購，商品將在3-5天內寄送您指定的地點。</p>
              <p class="mb-0 text-center">若有任何問題，請與客服聯繫。</p>
              <p class="text-center">——————————————————</p>
              <p class="text-center">Line官方帳號：<a href="https://lin.ee/Pg3oP7b">https://lin.ee/Pg3oP7b</a></p>
              <p class="text-center">客戶專線：<a href="tel:02-5569-7585">(02)5569-7585</a></p>
              <p class="text-center">郵件：<a href="mailto:service@mirada.com.tw">service@mirada.com.tw</a></p>
            </div>
            <div class="p-4">
              <button class="btn-forms btn-block md:w-1/2 md:mx-auto" @click="handleBack">回購物紀錄</button>
            </div>
          </section>
        </div>
      </article>
    </AuthBackContainer>
  </AuthContainer>
</template>

<script>
import AuthContainer from '@/components/auth/AuthContainer'
import AuthBackContainer from '@/components/auth/AuthBackContainer'
import { LogEnums } from '@/utils/enums'
import { mapActions, mapGetters } from 'vuex'
import { checkout, puchase } from '@/utils/ga.js'

export default {
  name: 'success',
  components: {
    AuthContainer,
    AuthBackContainer
  },
  data() {
    return {
      imgNotFound: 'this.src="' + require('../../assets/img/status/not_found_img_72x72.jpg') + '"',
      //orderNo: this.$route.query.CustomField1,
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'orderItem']),
    paymentType() {
      return decodeURIComponent(this.$route.query.PaymentType)
    },
    rtnCode() {
      return decodeURIComponent(this.$route.query.RtnCode)
    },
    isPaymentSuccess() {
      if(this.paymentType.includes('ATM') && this.rtnCode == 2) return true
      if(this.paymentType == 'Credit_CreditCard' && this.rtnCode == 1) return true
      if(this.paymentType == 'Cash.COCO' && this.rtnCode == 1) return true

      return false
    },
    isATMpayment() {
      return this.paymentType.includes('ATM')
    },
    isCreditcardPayment() {
      return this.paymentType == 'Credit_CreditCard'
    },
    expireDate() {
      return decodeURIComponent(this.$route.query.ExpireDate)
    },
    productImage(){
      return this.orderItem.items[0].prodImage;
    },
    productName(){
      return this.orderItem.items[0].prodName;
    },
    amount(){
      return this.orderItem.netAmount
    }
  },
  created() {
  },
  watch: {
    orderItem: {
      immediate: false,
      deep: false,
      handler() {
        if (!Object.prototype.hasOwnProperty.call(this.orderItem, 'items')) return
        const [actionField, products] = this.handleGaPurchase()
        this.$gtm.trackEvent(puchase(actionField, products))
      }
    }
  },
  mounted() {
    this.getOrderItem(this.$route.query.CustomField1)
    this.postLog({
      code: LogEnums.INTO_CHECKOUT_SUCCESS,
      data: JSON.stringify({
        order_no: this.$route.query.CustomField1
      })
    })
    this.$gtm.trackEvent(checkout({ step: 3, option: this.paymentType }))
  },
  methods: {
    ...mapActions(['postLog', 'getOrderItem']),
    handleBack() {
      this.$router.push('/orders')
    },
    handleGaPurchase() {
      console.log(this.orderItem)
      const items = this.orderItem.items.map(el => {
        return {
          name: el.prodName,
          id: el.prodId,
          price: el.amount,
          brand: el.suppName,
          quantity: el.quantity          
        }
      })
      return [{
        id: this.$route.query.CustomField1,
        revenue: this.amount,
      },
      items
      ]
    }
  },
}
</script>
